<template>
  <div id="app">
    <router-view v-if="isTelegramWebAppAvailable" />
    <div v-else class="text-xl font-bold w-full text-center mt-10">
      <p>Only available in the Telegram bot.</p>
      <el-empty />
    </div>
  </div>
</template>

<script setup>
import { onMounted, inject, ref } from 'vue'
const axios = inject('axios')
const isTelegramWebAppAvailable = ref(true)

const getTelegramUserId = () => {
  if (window.Telegram && window.Telegram.WebApp) {
    const user = window.Telegram.WebApp.initDataUnsafe.user
    if (user) {
      localStorage.setItem('telegramUserId', user.id)
    } else {
      isTelegramWebAppAvailable.value = false
    }
  } else {
    isTelegramWebAppAvailable.value = false
    console.error('Telegram WebApp 对象不存在')
  }
}

async function initializeApp() {
  const response = await axios.post("/create-user-info/", {
    // userid: "1234"
    userid: localStorage.getItem('telegramUserId')
  })
  localStorage.setItem("invitation_code", response.data.detail.invitation_code)
}

onMounted(() => {
  getTelegramUserId()
  getProductList()
  initializeApp()
})

const getProductList = async () => {
  try {
    const response = await axios.get('/query-esim-product-list-by-params/');
    localStorage.setItem('productList', JSON.stringify(response.data));

  } catch (err) {
    console.log(err);
  }
}
</script>


<style>
nav {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}
</style>

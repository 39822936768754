import { createI18n } from 'vue-i18n';

import en from './i18n/en.json';
import zh from './i18n/zh.json';
import de from './i18n/de.json';
import es from './i18n/es.json';
import ja from './i18n/ja.json';
import th from './i18n/th.json';
import tr from './i18n/tr.json';
import ko from './i18n/ko.json';

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    legacy: false,
    messages: {
        en,
        zh,
        de,
        es,
        ja,
        th,
        tr,
        ko
    },
});

export default i18n;

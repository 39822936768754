<template>
    <div class="bg-custom min-h-screen flex flex-col">
        <div class="aspect-ratio-box mt-7 cursor-pointer mx-auto">
            <img :src="card_background" />
            <div class="avatar-with-text">
                <div class="flex items-center justify-between">
                    <div class="text-white">
                        <div class="font-thin">{{ $t('Balance') }}</div>
                        <div class="mt-1 font-bold text-2xl">$ {{ myScore.toFixed(2) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-grow rounded-t-3xl bg-white overflow-auto p-5">
            <div class="flex justify-between items-center">
                <div class="font-semibold">
                    <div class="text-custom-green">{{ $t('SendInvite') }}</div>
                    <div class="mt-1 pr-3">{{ $t('InviteMsg') }}</div>
                </div>
                <img :src="recommendIcon" class="w-[40px] cursor-pointer" @click="goInvite">
            </div>
            <div class="mt-3 py-2 text-black font-medium border-b w-full">{{ $t('Balance_Records') }}</div>
            <div class="py-3 flex items-center justify-between" v-for="(invitation, index) in invitation_list"
                :key="index">
                <div>
                    <div class="text-black font-semibold">
                        {{ invitation.type === 0 ? $t('Referral_Bonus') :
                (invitation.type === 2 ? $t('Withdraw ') : $t('Welcome_Bonus')) }}
                    </div>

                    <div class="text-slate-500 text-sm">{{ convertToLocalTime(invitation.PaymentTime) }}</div>
                </div>
                <div class="text-lg font-semibold"
                    :class="{ 'text-custom-green': invitation.type !== 2, 'text-red-500': invitation.type === 2 }">
                    {{ invitation.type === 2 ? '- US$' + invitation.amount.toFixed(2) : '+ US$' + (invitation.type === 0
                ? '5.00' :
                '4.00') }}
                </div>
            </div>
            <el-empty :description="$t('No_Data')" v-if="invitation_list.length == 0" />
        </div>
    </div>

</template>
<script setup>
import { inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'
import { ElNotification } from 'element-plus'

const router = useRouter()

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.back();
    });
};

const { t } = useI18n();
const axios = inject('axios')
const timeService = inject('timeService');
const card_background = require('@/assets/img/card-bg-1.png')
const recommendIcon = require('@/assets/icons/recommend.png')
const myScore = ref(0)
const invitation_list = ref([])

onMounted(() => {
    getScoreOrder()
    getInvitationScore()
    setupBackButton();
})

const getScoreOrder = async () => {
    const response = await axios.get('/get_score_order/' + localStorage.getItem('telegramUserId'))
    invitation_list.value = response.data
}

const getInvitationScore = async () => {
    const response = await axios.get('/get-invitation-score/' + localStorage.getItem('telegramUserId'))
    myScore.value = response.data.invitation_score
};

const goInvite = async () => {
    const title = t('Share_Title');
    const text = t('Share_Text', { referralCode: localStorage.getItem('invitation_code') });
    const url = process.env.VUE_APP_BOT_URL;
    try {
        await navigator.share({
            title,
            text,
            url
        });
    } catch (error) {
        window.focus();
        console.log(text);
        await navigator.clipboard.writeText(text);
        ElNotification({ "message": t('Copied_Successfully'), type: "success" });
    }
}
const convertToLocalTime = timeService.convertToLocalTime
</script>

<style scoped>
.aspect-ratio-box {
    position: relative;
    width: 90%;
}

.aspect-ratio-box .avatar-with-text {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 1.3rem;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;
}


.aspect-ratio-box::before {
    content: '';
    display: block;
    padding-top: calc(225 / 585 * 100%);
}

.aspect-ratio-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.flex-grow {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.rounded-t-3xl.bg-white.overflow-auto {
    overflow-y: auto;
}
</style>
// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PackageView from '../views/PackageView.vue'
import CheckoutView from '../views/CheckoutView.vue'
import AccountView from '../views/AccountView.vue'
import EsimView from '../views/EsimView.vue'
import OrdersView from '../views/OrdersView.vue'
import PrivacyView from "../views/PrivacyView.vue"
import TermsView from "../views/TermsView.vue"
import ComplaintsView from "../views/ComplaintsView.vue"
import InvitationView from '@/views/InvitationView.vue'
// import StripeView from '@/views/StripeView.vue'
import ContactView from '@/views/ContactView.vue'
import InvitationListView from "@/views/InvitationListView.vue"

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/package/:countryCode',
    name: 'PackageView',
    component: PackageView
  },
  {
    path: '/checkout/:countryCode/:selectedPlanIndex',
    name: 'CheckoutView',
    component: CheckoutView
  },
  {
    path: '/account',
    name: 'AccountView',
    component: AccountView
  },
  {
    path: '/esim',
    name: 'EsimView',
    component: EsimView
  },
  {
    path: '/orders',
    name: 'OrdersView',
    component: OrdersView
  },
  {
    path: '/privacy',
    name: 'PrivacyView',
    component: PrivacyView
  },
  {
    path: '/complaints',
    name: 'ComplaintsView',
    component: ComplaintsView
  },
  {
    path: '/terms',
    name: 'TermsView',
    component: TermsView
  },
  {
    path: '/invitation',
    name: 'InvitationView',
    component: InvitationView
  },
  // {
  //   path: '/stripe/:orderNo',
  //   name: 'StripeView',
  //   component: StripeView
  // },
  {
    path: '/contact',
    name: 'ContactView',
    component: ContactView
  },
  {
    path: '/invitationlist',
    name: 'InvitationListView',
    component: InvitationListView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router

import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import App from './App.vue';
import router from './router'
import 'element-plus/dist/index.css'

import './tailwind.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import i18n from './i18n';
import axios from 'axios';
import 'flag-icon-css/css/flag-icons.css'
// import { loadStripe } from '@stripe/stripe-js';
import LoadingService from './utils/loadingManager';
import TimeService from './utils/timeService';


const app = createApp(App)

app.use(router)
app.use(ElementPlus)
app.use(i18n)

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.request.use(config => {
    // 启动loading
    LoadingService.start();
    return config;
}, error => {
    // 出错也要结束loading
    LoadingService.end();
    return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(response => {
    // 结束loading
    LoadingService.end();
    return response;
}, error => {
    // 出错也要结束loading
    LoadingService.end();
    return Promise.reject(error);
});

app.provide('axios', axios);
app.provide('timeService', TimeService);

// const stripe = loadStripe(process.env.VUE_APP_STRIPE_API_KEY);
// app.provide('stripe', stripe)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app');
import { ElLoading } from 'element-plus';

let loadingInstance;
let requestCount = 0;

const LoadingService = {
    start() {
        if (requestCount === 0) {
            // Element Plus 的 ElLoading.service 选项
            loadingInstance = ElLoading.service({ fullscreen: true });
        }
        requestCount++;
    },
    end() {
        if (requestCount <= 1) {
            // 关闭loading
            if (loadingInstance) {
                loadingInstance.close();
            }
            requestCount = 0;
        } else {
            requestCount--;
        }
    }
};

export default LoadingService;

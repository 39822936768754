import { toDate, format } from 'date-fns-tz';

export function convertToLocalTime(timeString) {
  if (!timeString) {
    return '';
  }

  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = toDate(`${timeString}Z`, { timeZone: 'UTC' });
    const formattedTime = format(utcDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });
    return formattedTime;
  } catch (error) {
    console.error('Error converting time:', error);
    return '';
  }
}



const TimeService = {
  convertToLocalTime
};

export default TimeService;

<template>
    <div class="bg-custom min-h-screen pt-5 pb-20 scroll-smooth">
        <div class="flex flex-col items-center justify-center w-full pt-10">
            <div :class="`flag-icon flag-icon-${countryCode.toLowerCase()}`" style="width: 60px;height:50px;"></div>
            <div class="no-cursor text-3xl font-bold mt-3">{{ $t(countryCode) }}</div>
            <div class="no-cursor text-xl mt-5 pb-5">{{ $t('Choose_a_data_plan') }}</div>
        </div>
        <div v-for="(plan, index) in planList" :key="index" @click="selectPlan(index)" :class="['flex flex-row align-center px-5 py-2 rounded-lg mb-3 mx-3 text-lg box-border',
                selectedPlan === index ? 'bg-selected border-blue-500 border' : 'bg-white']">
            <div class="basis-4/12">
                <div class="font-bold">{{ plan.GB }} GB</div>
                <div class="text-gray-400 font-semibold">{{ plan.ValidityDays }} {{ $t('Days') }}</div>
            </div>
            <div class="basis-4/12">
                <div class="font-bold">$ {{ plan.PriceUSD }}</div>
                <div class="text-gray-400 font-semibold">$ {{ (plan.PriceUSD / plan.GB).toFixed(2) }} / GB</div>
            </div>
            <div class="basis-4/12 flex flex-row justify-end items-center gap-4">
                <!-- <div v-if="plan.discount" class="text-red-500">{{ plan.discount }}</div> -->
                <img :src="selectedPlan === index ? radioOnIcon : radioCloseIcon" style="width: 20px;" />
            </div>
        </div>
        <div class="fixed bottom-0 w-full bg-custom-blue text-white flex items-center justify-center h-20 text-xl font-medium"
            @click="goToCheckout">
            {{ $t('Go_to_checkout') }}
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const radioCloseIcon = require('@/assets/icons/radio-close.png')
const radioOnIcon = require('@/assets/icons/radio-on.png')

const selectedPlan = ref(0)

const selectPlan = (index) => {
    selectedPlan.value = index
}

const router = useRouter()

onMounted(() => {
    setupBackButton()
})

const route = useRoute();
const countryCode = ref(route.params.countryCode)
const productListString = localStorage.getItem('productList')

const productList = JSON.parse(productListString)

const planList = ref(productList.find(item => Object.prototype.hasOwnProperty.call(item, countryCode.value))[countryCode.value])

const goToCheckout = () => {
    if (selectedPlan.value !== null) {
        router.push('/checkout/' + countryCode.value + "/" + selectedPlan.value)
    }
}

const setupBackButton = () => {
  var WebView = window.Telegram.WebView;
  WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
  WebView.onEvent("back_button_pressed", () => {
    router.back();
  });
};
</script>

<style scoped>
.bg-selected {
    background-color: #e8f6ff;
}

.border-blue-500 {
    border: 1px solid #3b82f6;
}
</style>

<template>
    <div class="bg-custom min-h-screen py-7 mx-auto" style="padding-left: 5%;padding-right: 5%">
        <div class="aspect-ratio-box cursor-pointer mx-auto">
            <img :src="card_background" />
            <div class="avatar-with-text">
                <div class="flex items-center justify-between">
                    <div class="text-white">
                        <div class="font-thin">{{ $t('Balance') }}</div>
                        <div class="mt-1 font-bold text-2xl">$ {{ myScore.toFixed(2) }}</div>
                    </div>
                </div>
            </div>
            <router-link class="bg-white/[.8] py-2 px-5 rounded-lg text-custom-green cursor-pointer text-sm"
                :to="{ name: 'InvitationListView' }">{{
                $t('Invitation_Details') }}</router-link>
        </div>
        <div class="pb-3 text-lg text-custom-green font-semibold">{{ $t('Invitation_Rules_Title') }}</div>
        <div class="font-semibold pb-1 text-sm">{{ $t('Referrer') }}</div>
        <div class="text-sm">{{ $t('Referrer_Rules') }}</div>
        <div class="font-semibold pb-1 pt-3 text-sm">{{ $t('Referred') }}</div>
        <div class="text-sm">{{ $t('Referred_Rules') }} </div>
        <div class="font-semibold pb-1 pt-3 text-sm">{{ $t('Notes') }}</div>
        <div class="text-sm">{{ $t('Note_Details_1') }}</div>
        <div class="text-sm">{{ $t('Note_Details_2') }}</div>
        <div class="text-sm">{{ $t('Note_Details_3') }}</div>
        <div class="pt-10 flex items-center">
            <img :src="invitationImg" class="h-[45px] w-[45px] mr-3" />
            <div class="w-full">
                <div class="text-sm">{{ $t('My_Invitation_Code') }}</div>
                <div class="flex w-full justify-between pt-0.2">
                    <div class="font-bold">{{ invitationCode }}</div>
                    <div class="text-custom-green font-semibold cursor-pointer" @click="copyText">{{ $t('Copy') }}</div>
                </div>
            </div>
        </div>
        <div class="mt-10 p-5 rounded-xl bg-custom-green text-white text-center font-semibold cursor-pointer"
            @click="goInvite">{{ $t('Invite_Now') }}
        </div>
    </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue'
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'
import { ElNotification } from 'element-plus'

const router = useRouter()

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.back();
    });
};

const axios = inject('axios')
const { t } = useI18n();
const invitationImg = require('@/assets/img/Invitation.png')
const card_background = require('@/assets/img/card-bg-1.png')
const invitationCode = ref(localStorage.getItem('invitation_code'))
const myScore = ref(0)


const copyText = async () => {
    window.focus();
    console.log("invitationCode:" + invitationCode.value);
    await navigator.clipboard.writeText(invitationCode.value);
    ElNotification({ "message": t('Copied_Successfully'), type: "success" });
};

onMounted(() => {
    getInvitationScore()
    setupBackButton();
})

const getInvitationScore = async () => {
    const response = await axios.get('/get-invitation-score/' + localStorage.getItem('telegramUserId'))
    myScore.value = response.data.invitation_score
};

const goInvite = async () => {
    const title = t('Share_Title');
    const text = t('Share_Text', { referralCode: localStorage.getItem('invitation_code') });
    console.log(text)
    const url = process.env.VUE_APP_BOT_URL;
    try {
        await navigator.share({
            title,
            text,
            url
        });
    } catch (error) {
        window.focus();
        await navigator.clipboard.writeText(text);
        ElNotification({ "message": t('Copied_Successfully'), type: "success" });
    }
}



</script>

<style scoped>
.aspect-ratio-box {
    position: relative;
    width: 100%;
}

.aspect-ratio-box .avatar-with-text {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 1.3rem;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;
}

.aspect-ratio-box a {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin-top: 2.2rem;
    margin-right: 1.5rem;
}


.aspect-ratio-box::before {
    content: '';
    display: block;
    padding-top: calc(225 / 585 * 100%);
}

.aspect-ratio-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}

.flex-grow {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}


.share-btn>a {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    font-family: Verdana, Arial;
}

.share-btn>a:hover {
    cursor: pointer;
}
</style>
<template>
    <div class="bg-custom min-h-screen py-5">
        <div class="pt-10 px-5 font-bold">{{ $t('My_eSIMs') }}</div>
        <el-radio-group v-model="tabPosition" class="px-5 pt-5">
            <el-radio-button value="Active">{{ $t('Active') }}</el-radio-button>
            <el-radio-button value="Archived">{{ $t('Archived') }}</el-radio-button>
        </el-radio-group>
        <div v-if="tabPosition == 'Active'">
            <div class="bg-white px-5 py-5 mx-5 mt-5 rounded-lg" v-for="(eSIM, index) in active_eSIMs" :key="index">
                <div class="flex items-center font-bold">
                    <el-avatar :src="eSIM.squareUrl" class="mr-2" />
                    <div>{{ eSIM.name }}</div>
                </div>
                <div class="flex justify-between border-b-2 mt-5 py-1">
                    <div>{{ $t('Purchase_date') }}</div>
                    <div>{{ eSIM.purchaseDate }}</div>
                </div>
                <div class="flex justify-between border-b-2 py-1">
                    <div>{{ $t('ICCID') }}</div>
                    <div>{{ eSIM.iccid }}</div>
                </div>
                <div class="flex justify-between border-b-2 py-1">
                    <div>{{ $t('Remaining_days') }}</div>
                    <div>{{ eSIM.remainingDays }}</div>
                </div>
                <div class="flex justify-between py-1">
                    <div>{{ $t('Remaining_data') }}</div>
                    <div>{{ eSIM.remainingData }}</div>
                </div>
            </div>
        </div>
        <div v-if="tabPosition == 'Archived'">
            <div class="bg-white px-5 py-5 mx-5 mt-5 rounded-lg" v-for="(eSIM, index) in archive_eSIMs" :key="index">
                <div class="flex items-center font-bold">
                    <el-avatar :src="eSIM.squareUrl" class="mr-2" />
                    <div>{{ eSIM.name }}</div>
                </div>
                <div class="flex justify-between border-b-2 mt-5 py-1">
                    <div>{{ $t('Purchase_date') }}</div>
                    <div>{{ eSIM.purchaseDate }}</div>
                </div>
                <div class="flex justify-between border-b-2 py-1">
                    <div>{{ $t('ICCID') }}</div>
                    <div>{{ eSIM.iccid }}</div>
                </div>
                <div class="flex justify-between border-b-2 py-1">
                    <div>{{ $t('Remaining_days') }}</div>
                    <div>{{ eSIM.remainingDays }}</div>
                </div>
                <div class="flex justify-between py-1">
                    <div>{{ $t('Remaining_data') }}</div>
                    <div>{{ eSIM.remainingData }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue'

const tabPosition = ref('Active')

const active_eSIMs = ref([
    {
        squareUrl: 'https://example.com/avatar1.png',
        name: 'Singapore 1 GB 7 days',
        purchaseDate: '23/07/2024, 00:02:08',
        iccid: '8986003209524938947',
        remainingDays: 5,
        remainingData: '500 MB',
    },
    {
        squareUrl: 'https://example.com/avatar2.png',
        name: 'Malaysia 2 GB 10 days',
        purchaseDate: '22/07/2024, 10:20:30',
        iccid: '8986003209524938948',
        remainingDays: 7,
        remainingData: '1.2 GB',
    },
    {
        squareUrl: 'https://example.com/avatar3.png',
        name: 'Thailand 3 GB 5 days',
        purchaseDate: '21/07/2024, 14:15:45',
        iccid: '8986003209524938949',
        remainingDays: 3,
        remainingData: '1 GB',
    },
    {
        squareUrl: 'https://example.com/avatar4.png',
        name: 'Vietnam 1.5 GB 7 days',
        purchaseDate: '20/07/2024, 09:00:00',
        iccid: '8986003209524938950',
        remainingDays: 4,
        remainingData: '750 MB',
    },
    {
        squareUrl: 'https://example.com/avatar5.png',
        name: 'Indonesia 5 GB 15 days',
        purchaseDate: '19/07/2024, 12:30:15',
        iccid: '8986003209524938951',
        remainingDays: 10,
        remainingData: '3 GB',
    },
])

const archive_eSIMs = ref([
    {
        squareUrl: 'https://example.com/avatar3.png',
        name: 'Thailand 3 GB 5 days',
        purchaseDate: '21/07/2024, 14:15:45',
        iccid: '8986003209524938949',
        remainingDays: 3,
        remainingData: '1 GB',
    },
])
</script>

<style scoped>
.el-radio-group {
    width: 100%;
}

.el-radio-button {
    width: 50% !important;
}

.el-radio-button__inner {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
    border: none !important;
}

.el-radio-button__inner:is-active {
    background-color: var(--el-color-primary) !important;
    color: white !important;
}
</style>
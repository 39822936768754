<template>
  <div class="bg-custom min-h-screen h-full py-2">
    <div class="flex pt-6 gap-4 px-5">
      <!-- Left part (Search) -->
      <div class="flex items-center border rounded px-2 bg-white rounded-lg flex-grow">
        <el-icon color="#b5b5b5">
          <Search />
        </el-icon>
        <input v-model="keywords" class="ml-2 outline-none w-full" type="text" :placeholder="$t('Search_placeholder')"
          @change="handleKeywordChange" />
        <button @click="clearInput" v-if="keywords">
          <el-icon color="#b5b5b5">
            <CircleCloseFilled />
          </el-icon>
        </button>
      </div>
      <!-- Right part (Account) -->
      <button class="flex flex-col items-center justify-center text-custom-green" @click="goToAccount">
        <img :src="accountIcon" style="height:20px;width:20px" />
        <span class="text-sm cursor-none">{{ $t('Account') }}</span>
      </button>
      <button class="flex flex-col items-center justify-center text-custom-green" @click="goToInvitation">
        <img :src="invitationIcon" style="height:20px;width:20px" />
        <span class="text-sm cursor-none">{{ $t('Invitation') }}</span>
      </button>
    </div>
    <div class="pt-3 px-5 text-3xl font-bold">{{ $t('Internet') }}</div>
    <!-- Countries -->
    <div>
      <div class="pt-4 pb-6 px-5 text-xl font-semibold">{{ $t('Popular_countries') }}</div>
      <div v-for="(productsByCountry, index) in productList" :key="`group-${index}`">
        <!-- 遍历每个国家的产品列表 -->
        <router-link v-for="(products, country) in productsByCountry" :key="country"
          :to="{ name: 'PackageView', params: { countryCode: country } }">
          <div class="flex px-5 mx-5 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
            <div class="flex items-center gap-4">
              <div :class="`flag-icon flag-icon-${country.toLowerCase()}`" style="width: 40px;height:30px;"></div>
              <span class="cursor-none">{{ $t(country) }}</span>
            </div>
            <el-icon color="#b5b5b5">
              <ArrowRightBold />
            </el-icon>
          </div>
        </router-link>
      </div>
    </div>
    <!-- Regions -->
    <div v-if="tabPosition == 'regions'">
      <div class="pt-6 pb-6 px-5 text-xl font-semibold">{{ $t('Regions') }}</div>
    </div>
    <!-- Global -->
    <div v-if="tabPosition == 'global'">
      <div class="pt-6 pb-6 px-5 text-xl font-semibold">{{ $t('Global') }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import { ElNotification } from 'element-plus'
import { useI18n } from 'vue-i18n';


const { t } = useI18n();
const axios = inject('axios')
const router = useRouter()
const route = useRoute()
const keywords = ref('')
const productList = ref()

const accountIcon = require('@/assets/icons/account.png')
const invitationIcon = require('@/assets/icons/invitation.png')

const setupBackButton = () => {
  var WebView = window.Telegram.WebView;
  WebView.postEvent('web_app_setup_back_button', false, { is_visible: false });
};

onMounted(() => {
  setupBackButton();
  paymentMessage();
  const intervalId = setInterval(() => {
    const productListStr = localStorage.getItem('productList');
    if (productListStr) {
      productList.value = JSON.parse(productListStr);
      clearInterval(intervalId);
    }
  }, 1000);
});

const paymentMessage = () => {
  const status = route.query.status;

  if (status === 'paymentSuccess') {
    const referrer = document.referrer;

    const referrerHostname = new URL(referrer).hostname;
    const allowedReferrerHostname = 'lksdfjaw.gowisers.com';

    if (referrerHostname === allowedReferrerHostname) {
      ElNotification({ "message": t('PaymentSuccessful'), type: "success" });
    }
  }
}

const handleKeywordChange = () => {
  getProductList(keywords.value)
}

const clearInput = () => {
  keywords.value = ''
  getProductList(keywords.value)
}

const goToAccount = () => {
  router.push('/account')
}

const goToInvitation = () => {
  router.push('/invitation')
}

const getProductList = async () => {
  try {
    const response = await axios.get('/query-esim-product-list-by-params/', {
      params: { name: keywords.value }
    });
    productList.value = response.data

  } catch (err) {
    console.log(err);
  }
}

</script>

<style>
.el-radio-group {
  width: 100%;
}

.el-radio-button {
  width: calc(100% / 3) !important;
}

.el-radio-button__inner {
  width: 100% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
  border: none !important;
}

.el-radio-button__inner:is-active {
  background-color: var(--el-color-primary) !important;
  color: white !important;
}
</style>
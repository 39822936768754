<template>
    <div class="bg-custom container mx-auto px-4 py-5">
        <div v-html="$t('Complaints_Content')" class="custom-style"></div>
    </div>

</template>
<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
onMounted(() => {
    setupBackButton();
})

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.back();
    });
};

</script>
<template>
    <div class="bg-custom min-h-screen py-5">
        <div class="mt-10 bg-white rounded-lg px-5 py-5 mb-3 mx-3 font-semibold text-lg">
            <div class="flex items-center">
                <div :class="`flag-icon flag-icon-${countryCode.toLowerCase()}`" style="width: 60px;height:50px;"></div>
                <div class="pl-5 text-xl">{{ $t(countryCode) }}</div>
            </div>
            <div class="flex justify-between mt-3 pt-2 border-b border-neutral-400 pb-2">
                <div>{{ $t('Data') }}</div>
                <div>{{ selectedPlan.GB }} GB</div>
            </div>
            <div class="flex justify-between pt-2 border-b border-neutral-400 pb-2">
                <div>{{ $t('Validity') }}</div>
                <div>{{ selectedPlan.ValidityDays }} {{ $t('Days') }}</div>
            </div>
            <div class="flex justify-between pt-2">
                <div>{{ $t('Price') }}</div>
                <div>$ {{ selectedPlan.PriceUSD }}</div>
            </div>
        </div>

        <el-input v-model="email" clearable :placeholder="$t('emailPlaceholder')" class="px-3 h-12 my-3">
            <template #prepend><span class="text-red-600 pr-2">* </span><el-icon>
                    <Message />
                </el-icon></template>
        </el-input>
        <el-input v-model="invitationCode" clearable :placeholder="$t('inviteCodePlaceholder')" class="px-3 h-12 my-3"
            v-if="!order_exists">
            <template #prepend><el-icon>
                    <Avatar />
                </el-icon></template>
        </el-input>

        <div class="px-3 pb-2 text-gray-400 tracking-widest">{{ $t('Payment_methods') }}</div>
        <!-- <div class="flex px-5 mx-3 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg"
            @click="handlePaymentClick('stripe')">
            <div class="flex items-center gap-4">
                <el-avatar :src="creditCardIcon" />
                <span class="cursor-none">VISA / Mastercard</span>
            </div>
            <el-icon color="#b5b5b5">
                <ArrowRightBold />
            </el-icon>
        </div> -->
        <div class="flex px-5 mx-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg"
            @click="handlePaymentClick('gowisers')">
            <div class="flex items-center gap-4">
                <el-avatar :src="usdtIcon"></el-avatar>
                <div class="font-semibold">USDT</div>
            </div>
            <el-icon color="#b5b5b5">
                <ArrowRightBold />
            </el-icon>
        </div>
        <div class="flex px-5 mx-3 rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg"
            :class="{ 'bg-white': myScore >= selectedPlan.PriceUSD, 'bg-custom-grey': myScore < selectedPlan.PriceUSD }"
            @click="dialogVisible = myScore >= selectedPlan.PriceUSD ? true : dialogVisible">
            <div class="flex items-center gap-4">
                <el-avatar :src="myScore >= selectedPlan.PriceUSD ? walletIcon : walletDisableIcon"></el-avatar>
                <div>
                    <div class="flex font-semibold">
                        <div>{{ $t('Mede_Points') }}</div>
                        <div class="pl-2">${{ myScore }}</div>
                    </div>
                    <div v-if="myScore < selectedPlan.PriceUSD" class="text-sm text-red-500">{{
                    $t('Insufficient_Points') }}
                    </div>
                </div>
            </div>
            <el-icon color="#b5b5b5">
                <ArrowRightBold />
            </el-icon>
        </div>
        <el-dialog v-model="dialogVisible" width="350" class="rounded-lg">
            <div class="border-b text-custom-green text-center text-2xl font-bold py-3">
                {{ $t('Confirm_Payment') }}
            </div>
            <div class="py-6 w-full text-center">
                <div class="text-2xl font-semibold text-custom-green">${{ selectedPlan.PriceUSD.toFixed(2) }}</div>
                <div class="mt-1 text-gray-400">{{ $t('Payment_Amount') }}</div>
            </div>
            <div class="bg-custom w-full p-3 flex items-center text-lg justify-between rounded-lg">
                <div class="flex items-center">
                    <img :src="walletIcon1" style="height:30px;width:30px" />
                    <div class="ml-1">{{ $t('Available_Balance') }}：</div>
                </div>
                <div class="text-custom-green font-semibold">${{ myScore.toFixed(2) }}</div>
            </div>
            <div class="my-5 w-full bg-custom-green p-3 rounded-lg text-lg text-white text-center cursor-pointer"
                @click="handlePaymentClick('point')">{{
                    $t('Confirm_Payment') }}</div>
            <div class="text-sm text-gray-400" v-if="!order_exists">{{ $t('Withdraw_Rules') }}</div>
        </el-dialog>
    </div>
</template>
<script setup>
import { inject, ref, onMounted } from 'vue'
import { ElNotification } from 'element-plus'
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router'

const axios = inject('axios')

const router = useRouter()
const route = useRoute()


const email = ref("")
const invitationCode = ref("")
const { t } = useI18n();

const productListString = localStorage.getItem('productList')
const productList = JSON.parse(productListString)
const countryCode = ref(route.params.countryCode)
const selectedPlanIndex = ref(route.params.selectedPlanIndex)
const planList = ref(productList.find(item => Object.prototype.hasOwnProperty.call(item, countryCode.value))[countryCode.value])
const selectedPlan = ref(planList.value[selectedPlanIndex.value])
const myScore = ref(0)
const dialogVisible = ref(false)


const usdtIcon = require('@/assets/icons/USDT.png')
// const creditCardIcon = require('@/assets/icons/credit-card.png')
const walletIcon = require('@/assets/icons/wallet.png')
const walletIcon1 = require('@/assets/icons/wallet_1.png')
const walletDisableIcon = require('@/assets/icons/wallet_disable.png')

const order_exists = ref(true)

onMounted(() => {
    checkSuccessfulOrder()
    getInvitationScore()
    setupBackButton();
})

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.back();
    });
};

const checkSuccessfulOrder = async () => {
    const response = await axios.get('/check_successful_order/' + localStorage.getItem('telegramUserId'))
    order_exists.value = response.data.detail.order_exists
}

const getInvitationScore = async () => {
    const response = await axios.get('/get-invitation-score/' + localStorage.getItem('telegramUserId'))
    myScore.value = response.data.invitation_score
};

const handlePaymentClick = async (payment_method) => {
    if (!checkEmailAddress()) {
        ElNotification({ "message": t('emailMessage'), type: "error" });
        return;
    }
    if (payment_method == "gowisers") {
        try {
            const response = await axios.post('/create-gowisers-order/', {
                userId: localStorage.getItem("telegramUserId"),
                productId: selectedPlan.value.ProductID,
                email: email.value,
                invitationCode: invitationCode.value,
            });
            ElNotification({ "message": t('GowiserCheckoutMsg'), type: "success" });
            var WebView = window.Telegram.WebView;
            WebView.postEvent('web_app_setup_back_button', false, { is_visible: false });
            setTimeout(() => {
                window.location.href = response.data.detail.data.payUrl;
            }, 3000);
        } catch (error) {
            console.error(error.response || error);
            if (error.response) {
                console.error('Error data:', error.response.data);
                ElNotification({ "message": error.response.data.message || 'An error occurred', type: "error" });
            } else {
                ElNotification({ "message": 'An error occurred', type: "error" });
            }
        }
    } else if (payment_method == "point") {
        const response = await axios.post('/purchase-with-score/', {
            userid: localStorage.getItem("telegramUserId"),
            productId: selectedPlan.value.ProductID
        });
        if (response.data.status == "success") {
            const res = await axios.post('/create-db-order/', {
                userId: localStorage.getItem("telegramUserId"),
                productId: selectedPlan.value.ProductID,
                email: email.value,
                invitationCode: '',
            });
            axios.post('/open-card/', {
                orderNo: res.data
            }).then(response => {
                console.log(response)
                ElNotification({ "message": t('PaymentSuccessful'), type: "success" });
                router.push('/orders');
            }).catch(error => {
                // 处理错误情况
                console.error("There was an error!", error);
            });
        }
    }
    else if (payment_method == "stripe") {
        // const response = await axios.post('/create-db-order/', {
        //     userId: localStorage.getItem("telegramUserId"),
        //     productId: selectedPlan.value.ProductID,
        //     email: email.value,
        //     invitationCode: invitationCode.value,
        // });
        // router.push('/stripe/' + response.data)
    }

}

const checkEmailAddress = () => {
    const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexPattern.test(email.value);
};

</script>
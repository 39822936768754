<template>
    <div class="bg-custom min-h-screen py-10 px-5 w-full text-center">
        <div class="font-bold text-2xl">
            <div>{{ $t('help') }}</div>
            <div>{{ $t('contactUs') }}</div>
        </div>
        <div class="mt-3">{{ $t('teamSupport') }}</div>
        <div class="mt-5 w-11/12 bg-white mx-auto rounded-xl p-5">
            <div class="aspect-ratio-box w-full cursor-pointer" @click="navToContact('tgSupportCustomerService1')">
                <img :src="card_background" />
                <div class="avatar-with-text w-full">
                    <el-avatar :src="tg_service" />
                    <div class="mt-3 w-full flex justify-between items-center">
                        <div class="text-sm">{{ $t('tgSupportCustomerService') }}</div>
                        <el-image :src="right" style="height: 12px;width:12px;margin-right:2rem"></el-image>
                    </div>
                </div>
            </div>
            <div class="aspect-ratio-box w-full mt-5 cursor-pointer" @click="navToContact('tgSupportCustomerService2')">
                <img :src="card_background" />
                <div class="avatar-with-text w-full">
                    <el-avatar :src="tg_service" />
                    <div class="mt-3 w-full flex justify-between items-center">
                        <div class="text-sm">{{ $t('tgSupportCustomerService') }}</div>
                        <el-image :src="right" style="height: 12px;width:12px;margin-right:2rem"></el-image>
                    </div>
                </div>
            </div>
            <div class="aspect-ratio-box w-full mt-5 cursor-pointer" @click="navToContact('tgGroup1')">
                <img :src="card_background" />
                <div class="avatar-with-text w-full">
                    <el-avatar :src="tg" />
                    <div class="mt-3 w-full flex justify-between items-center">
                        <div class="text-sm">{{ $t('tgGroup1') }}</div>
                        <el-image :src="right" style="height: 12px;width:12px;margin-right:2rem"></el-image>
                    </div>
                </div>
            </div>
            <div class="aspect-ratio-box w-full mt-5 cursor-pointer" @click="navToContact('tgGroup2')">
                <img :src="card_background" />
                <div class="avatar-with-text w-full">
                    <el-avatar :src="tg" />
                    <div class="mt-3 w-full flex justify-between items-center">
                        <div class="text-sm">{{ $t('tgGroup2') }}</div>
                        <el-image :src="right" style="height: 12px;width:12px;margin-right:2rem"></el-image>
                    </div>
                </div>
            </div>
            <div class="aspect-ratio-box w-full mt-5 cursor-pointer" @click="navToContact('Xiaohongshu')">
                <img :src="card_background" />
                <div class="avatar-with-text w-full">
                    <el-avatar :src="xiaohongshu" />
                    <div class="mt-3 w-full flex justify-between items-center">
                        <div class="text-sm">{{ $t('Xiaohongshu') }}</div>
                        <el-image :src="right" style="height: 12px;width:12px;margin-right:2rem"></el-image>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
<script setup>

const card_background = require('@/assets/img/card-bg.png')
const tg_service = require('@/assets/icons/tg_service.png')
const tg = require('@/assets/icons/tg.png')
const xiaohongshu = require('@/assets/icons/xiaohongshu.png')
const right = require('@/assets/icons/right.png')

import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
onMounted(() => {
    setupBackButton();
})

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.back();
    });
};


const navToContact = (channel) => {
    if (channel === "tgSupportCustomerService1") {
        window.location.href = process.env.VUE_APP_TG_SUPPORT1
    } else if (channel === "tgSupportCustomerService2") {
        window.location.href = process.env.VUE_APP_TG_SUPPORT2
    } else if (channel === "tgGroup1") {
        window.location.href = process.env.VUE_APP_TG_GROUP1
    } else if (channel === "tgGroup2") {
        window.location.href = process.env.VUE_APP_TG_GROUP2
    } else if (channel === "Xiaohongshu") {
        window.open(process.env.VUE_APP_XIAOHONGSHU, '_blank');
    }
}
</script>

<style scoped>
.aspect-ratio-box {
    position: relative;
    width: 100%;
}

.aspect-ratio-box .avatar-with-text {
    position: absolute;
    top: 15%;
    left: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;
}


.aspect-ratio-box::before {
    content: '';
    display: block;
    padding-top: calc(225 / 585 * 100%);
}

.aspect-ratio-box img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
}
</style>
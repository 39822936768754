<template>
    <div class="container mx-auto px-4 py-5 bg-custom">
        <div v-html="$t('Privacy_Content')" class="custom-style"></div>
    </div>
</template>
<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
onMounted(() => {
    setupBackButton();
})

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.back();
    });
};

</script>
<template>
    <div class="bg-custom min-h-screen py-5">
        <div class="pt-10 px-5 font-bold">{{ $t('My_Orders') }}</div>

        <el-radio-group v-model="tabPosition" class="px-5 py-2 mt-3">
            <el-radio-button value="1">{{ $t('Paid') }}</el-radio-button>
            <el-radio-button value="0">{{ $t('Unpaid') }}</el-radio-button>
        </el-radio-group>

        <div v-for="(order, index) in orderList" :key="index">
            <div class="bg-white px-5 py-5 mx-5 mt-5 rounded-lg" v-if="order.StatusCode === tabPosition">
                <div class="flex items-center justify-between font-bold">
                    <div class="flex items-center">
                        <div :class="`flag-icon flag-icon-${order.CountryRegionCode.toLowerCase()}`"
                            style="width: 60px;height:50px;"></div>
                        <div class="ml-3">{{ $t(order.CountryRegionCode) }} {{ order.GB }} GB {{ order.ValidityDays }}
                            {{
            $t('Days') }}
                        </div>
                    </div>
                    <div class="text-2xl">${{ order.Price }}</div>
                </div>
                <div class="flex justify-between border-b-2 mt-5 py-1">
                    <div>{{ $t("Order_No") }}</div>
                    <div>{{ order.OrderNo }}</div>
                </div>
                <div class="flex justify-between border-b-2 py-1">
                    <div>{{ $t("Payment_Time") }}</div>
                    <div>{{ convertToLocalTime(order.PaymentTime) }}</div>
                </div>
                <div class="flex justify-between py-1">
                    <div>{{ $t("Status") }}</div>
                    <div>{{ order.StatusCode === "1" ? $t('paid') : $t('Unpaid') }}</div>
                </div>
            </div>
        </div>
        <el-empty :description="$t('No_Data')" v-if="orderList.length == 0" />
    </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'

const axios = inject('axios')
const router = useRouter()
const timeService = inject('timeService');

onMounted(() => {
    getMyOrderList()
    setupBackButton()
})

const orderList = ref([])
const tabPosition = ref("1")

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.push('/');
    });
};

const getMyOrderList = async () => {
    const res = await axios.get("/query-order-list-by-userid/", {
        params: { userid: localStorage.getItem("telegramUserId") }
    })
    orderList.value = res.data
}
const convertToLocalTime = timeService.convertToLocalTime
</script>

<style>
.el-radio-group {
    width: 100%;
}

.el-radio-button {
    width: calc(100% / 2) !important;
}

.el-radio-button__inner {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
    border: none !important;
}

.el-radio-button__inner:hover {
    background-color: #7A9440 !important;
    color: white !important;
}

.el-radio-button__original-radio:checked+.el-radio-button__inner {
    background-color: #7A9440 !important;
}
</style>
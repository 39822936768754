<template>
    <div class="bg-custom min-h-screen py-5">
        <div class="px-5">
            <div class="text-xl font-bold pt-8">{{ $t('Account') }}</div>
            <div class="text-lg pt-3 text-gray-400 tracking-widest">{{ $t('Profile') }}</div>
            <!-- <router-link :to="{ name: 'EsimView' }">
                <div
                    class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
                    <div class="flex items-center gap-4">
                        <el-avatar :src="esimIcon" />
                        <span class="cursor-none">{{ $t('My_eSIMs') }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </router-link> -->
            <router-link :to="{ name: 'OrdersView' }">
                <div
                    class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
                    <div class="flex items-center gap-4">
                        <el-avatar :src="orderIcon" />
                        <span class="cursor-none">{{ $t('My_Orders') }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'InvitationView' }">
                <div
                    class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
                    <div class="flex items-center gap-4">
                        <el-avatar :src="invitationIcon" />
                        <span class="cursor-none">{{ $t('My_Invitations') }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </router-link>
            <div class="text-lg text-gray-400 tracking-widest">{{ $t('Settings') }}</div>
            <div class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg scrollbar-hide"
                @click="openDialog">
                <div class="flex items-center gap-4">
                    <el-avatar :src="languageIcon" />
                    <span class="cursor-none">{{ $t('Language') }}</span>
                </div>
                <div class="flex items-center">
                    <div class="text-custom-green pr-2">{{ selectedLanguage.label }}</div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </div>
            <div class="text-lg text-gray-400 tracking-widest">{{ $t('Other') }}</div>
            <router-link :to="{ name: 'PrivacyView' }">
                <div
                    class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
                    <div class="flex items-center gap-4">
                        <el-avatar :src="privacyIcon" />
                        <span class="cursor-none">{{ $t('Privacy_notes') }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'ComplaintsView' }">
                <div
                    class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
                    <div class="flex items-center gap-4">
                        <el-avatar :src="complaintsIcon" />
                        <span class="cursor-none">{{ $t('Complaints_policy') }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'TermsView' }">
                <div
                    class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
                    <div class="flex items-center gap-4">
                        <el-avatar :src="termsIcon" />
                        <span class="cursor-none">{{ $t('Terms_and_conditions') }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </router-link>
            <router-link :to="{ name: 'ContactView' }">
                <div
                    class="flex px-5 mt-3 bg-white rounded-lg h-16 items-center justify-between mb-3 cursor-pointer text-lg">
                    <div class="flex items-center gap-4">
                        <el-avatar :src="contactIcon" />
                        <span class="cursor-none">{{ $t('Contact_us') }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </router-link>
        </div>
        <el-dialog v-model="dialogVisible" :title="$t('Select_Language')" width="300" class="rounded-lg">
            <div class="max-h-64 overflow-y-auto ">
                <div v-for="language in languages" :key="language.name"
                    class="flex items-center justify-between gap-4 mb-2 cursor-pointer bg-gray-100 p-2 rounded-lg"
                    @click="selectLanguage(language)">
                    <div class="flex items-center gap-4">
                        <div :class="`flag-icon flag-icon-${language.code}`"></div>
                        <span>{{ language.label }}</span>
                    </div>
                    <el-icon color="#b5b5b5">
                        <ArrowRightBold />
                    </el-icon>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router'

const router = useRouter()

const { locale } = useI18n();

const dialogVisible = ref(false);
const languages = ref([
    { name: 'en', label: 'English', code: 'gb' },
    { name: 'zh', label: '简体中文', code: 'cn' },
    { name: 'es', label: 'Español', code: 'es' },
    { name: 'ja', label: '日本語', code: 'jp' },
    { name: 'de', label: 'Deutsch', code: 'de' },
    { name: 'ko', label: '한국어', code: 'kr' },
    { name: 'th', label: 'ไทย', code: 'th' },
    { name: 'tr', label: 'Türkçe', code: 'tr' }
]);
const selectedLanguage = ref({ name: 'en', label: 'English' });

const languageIcon = require('@/assets/icons/lang.png')
// const esimIcon = require('@/assets/icons/SIM.png')
const orderIcon = require('@/assets/icons/order.png')
const invitationIcon = require('@/assets/icons/invite.png')
const privacyIcon = require('@/assets/icons/privacy.png')
const termsIcon = require('@/assets/icons/terms.png')
const contactIcon = require('@/assets/icons/contact.png')
const complaintsIcon = require('@/assets/icons/complaints.png')


onMounted(() => {
    const language = languages.value.find(lang => lang.name === locale.value);
    if (language) {
        selectedLanguage.value = language;
    }
    setupBackButton();
})

const setupBackButton = () => {
    var WebView = window.Telegram.WebView;
    WebView.postEvent('web_app_setup_back_button', false, { is_visible: true });
    WebView.onEvent("back_button_pressed", () => {
        router.push('/')
    });
};

const openDialog = () => {
    dialogVisible.value = true;
};

const selectLanguage = (language) => {
    selectedLanguage.value = language;
    locale.value = language.name;
    dialogVisible.value = false;
};
</script>


<style scoped>
.el-avatar {
    background: white !important;
}

:deep(.el-dialog__title) {
    color: #7A9440 !important;
}

:deep(.scrollbar-hide::-webkit-scrollbar) {
    display: none !important;
}

:deep(.scrollbar-hide) {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}
</style>